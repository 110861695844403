import { Component, DestroyRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { catchError, filter, map, of } from 'rxjs';
import { ApiErrorResponse, AuthState, BannerService, ErrorHandlerV2Service, GeaMessage, UserState } from '@gea/digital-ui-lib';
import { ToggleButtonChangeEvent } from 'primeng/togglebutton';
import { TranslateService } from '@ngx-translate/core';

import { AssetService } from '../../services';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { ProductSelectionService } from '../../services/product-selection.service';
import { IsMobileViewService } from '../../services/is-mobile-view.service';
import { MsalService } from '@azure/msal-angular';
import { DocumentGroup, ProductPassport } from '../../api/v1';
import { Store } from '@ngxs/store';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../../environments/models/environment.model';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'advance-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, OnDestroy {
  readonly SUPPORT_APP_BASE_URL = this.environment.advance.supportAppBaseUrl;
  readonly LOGIN_INFO_KEY = 'loginInfo';
  readonly SUPPORT_APP_FEATURE: string = 'core_support.enabled';
  readonly DOCUMENT_APP_FEATURE: string = 'core_document.enabled';

  geaPUID: string;
  product!: ProductPassport;
  publicDocuments: DocumentGroup[] = [];
  showStatus = true;
  showDocumentDownload = false;
  loading = false;
  isLoggedIn = false;
  hasDocumentAppAccess = false;
  hasSupportAppAccess = false;
  hasPublicDocuments = false;
  showBackToTopButton = false;
  productInformationIsSticky = false;
  stickyScrollPointReached = false;
  isMobileView = false;
  geauiPageLayoutContent: HTMLElement | null;

  // initialized as empty function to avoid null pointer exceptions
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  scrollingListener: () => void = () => {};

  constructor(
    public translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private assetService: AssetService,
    private bannerService: BannerService,
    private store: Store,
    public errorHandlerService: ErrorHandlerV2Service,
    private featureFlagService: FeatureFlagService,
    private productSelectionService: ProductSelectionService,
    private isMobileViewService: IsMobileViewService,
    private authService: MsalService,
    private renderer: Renderer2,
    private destroyed: DestroyRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration
  ) {
    this.geaPUID = this.activatedRoute.snapshot.paramMap.get('geaPUID') as string;
    this.geauiPageLayoutContent = this.document.getElementById('geaui-page-layout-content');
  }

  ngOnInit() {
    this.loading = true;
    this.listenToContentScroll();

    this.store
      .select(UserState.loggedOutPageReady)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((ready) => {
        if (!!ready && !this.isLoggedIn) {
          this.getProduct();
        }
      });

    this.store
      // This is the official syntax of ngxs
      // eslint-disable-next-line @typescript-eslint/unbound-method
      .select(UserState.authState)
      .pipe(
        takeUntilDestroyed(this.destroyed),
        filter((authState) => authState !== AuthState.NotSet),
        map((authState) => authState === AuthState.LoggedIn)
      )
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        this.toggleLoginBanner();
        this.getProduct();

        if (isLoggedIn) {
          this.getFeatureFlags();
        }
      });

    this.isMobileViewService.isMobileView$.pipe(takeUntilDestroyed(this.destroyed)).subscribe((isMobileView) => {
      this.isMobileView = isMobileView;
      this.toggleLoginBanner();
      this.toggleStickyProductInformation();
    });
  }

  getProduct() {
    const getProduct$ = this.assetService.getProduct(this.geaPUID, this.isLoggedIn).pipe(
      takeUntilDestroyed(this.destroyed),
      catchError((error: ApiErrorResponse) => {
        this.errorHandlerService.handleError(error);
        return of(undefined);
      })
    );

    getProduct$.pipe(takeUntilDestroyed(this.destroyed)).subscribe((productDetails?: ProductPassport) => {
      if (productDetails) {
        this.product = productDetails;
        const productDocuments = productDetails.documents;

        if (productDocuments) {
          this.publicDocuments = productDocuments;
          this.hasPublicDocuments = productDocuments.length > 0;
        }
        this.toggleLoginBanner();
      }
      this.loading = false;
    });
  }

  getFeatureFlags() {
    this.featureFlagService
      .getFeatureFlagConfigurationForUser()
      .pipe(
        takeUntilDestroyed(this.destroyed),
        catchError((error: ApiErrorResponse) => {
          // No need to show error banner if feature flag call fails
          // eslint-disable-next-line no-console
          console.error('An error occurred when requesting the user feature configuration.', error);
          return of(undefined);
        })
      )
      .subscribe((featureFlagConfiguration) => {
        if (featureFlagConfiguration) {
          const features = featureFlagConfiguration.features;
          this.hasDocumentAppAccess = this.DOCUMENT_APP_FEATURE in features;
          this.hasSupportAppAccess = this.SUPPORT_APP_FEATURE in features;
        }
      });
  }

  listenToContentScroll() {
    if (this.geauiPageLayoutContent) {
      this.scrollingListener = this.renderer.listen(this.geauiPageLayoutContent, 'scroll', () => {
        const scrollTop = this.geauiPageLayoutContent?.scrollTop ?? 0;
        this.showBackToTopButton = scrollTop != 0;

        if (scrollTop > 45) {
          this.stickyScrollPointReached = true;
        } else if (scrollTop <= 35) {
          this.stickyScrollPointReached = false;
        }

        this.toggleStickyProductInformation();
      });
    }
  }

  toggleLoginBanner() {
    this.bannerService.clear(this.LOGIN_INFO_KEY);

    if (!this.isLoggedIn && this.product?.showLoginInfo) {
      this.showLoginBanner();
    }
  }

  toggleStickyProductInformation() {
    this.productInformationIsSticky = this.isMobileView && this.stickyScrollPointReached;
  }

  showLoginBanner() {
    if (this.isMobileView) {
      this.addBanner('ASSET-DETAIL.BANNER-INFO.MOBILE');
    } else {
      this.addBanner('ASSET-DETAIL.BANNER-INFO');
    }
  }

  addBanner(detailText: string) {
    this.bannerService.add({
      key: this.LOGIN_INFO_KEY,
      severity: 'info',
      detail: this.translateService.instant(detailText) as string,
      data: {
        hasCloseButton: true,
        hasBannerButton: true,
        bannerButtonLabel: this.translateService.instant('ASSET-DETAIL.BANNER-BUTTON.LABEL') as string,
        bannerButtonClicked: () => {
          this.authService.loginRedirect();
        },
      },
    } as GeaMessage);
  }

  toggleStatusPanel(toggleEvent: ToggleButtonChangeEvent) {
    this.showStatus = toggleEvent.checked ?? false;
  }

  openSupportApp() {
    this.productSelectionService
      .updateSelectedProducts(this.product.registeredProductId)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe({
        next: () => {
          this.openSupportAppUrl();
        },
        error: (error: ApiErrorResponse) => {
          // Log error to console and still redirect user to support app
          // eslint-disable-next-line no-console
          console.error('An error occurred while trying to update selected products.', error);
          this.openSupportAppUrl();
        },
      });
  }

  openSupportAppUrl() {
    window.open(this.SUPPORT_APP_BASE_URL);
  }

  openDocumentApp() {
    // TODO implement logic to redirect user to the documentApp
  }

  public scrollToTop() {
    this.geauiPageLayoutContent?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    this.bannerService.clear();
    // Call ensures that the scroll event is unsubscribed
    this.scrollingListener();
  }
}
