<div class="home-content geaui-grid geaui-sidebar-grid geaui-scrollable-table">
  <div
    class="flex-direction-row align-center geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-5 geaui-col-desktop-lg-6 geaui-col-desktop-md-6 geaui-col-tablet-lg-4 geaui-col-tablet-sm-4 geaui-col-phone-lg-4 geaui-col-phone-sm-2">
    <span class="gea-font-heading-24" data-cy="title">{{ 'ASSET-LIST.TITLE' | translate }}</span>
    <geaui-button-group-v2
      [(activeItem)]="activeButtonItem"
      [buttonItems]="buttonItems"
      (activeItemChange)="onChangeTableViewMode($event)">
    </geaui-button-group-v2>
  </div>
  <div
    class="align-center geaui-col-phone-sm-2 geaui-col-phone-lg-4 geaui-col-tablet-sm-3 geaui-col-desktop-md-3 geaui-col-desktop-lg-3 geaui-col-desktop-xl-3">
    <div class="geaui-fieldset-toggle-box" *ngIf="isTempUser" data-cy="show-excluded-products-container">
      <span data-cy="show-excluded-products-title" data-cy="label">{{ 'ASSET-LIST.SHOW-EXCLUDED-PRODUCTS' | translate }}</span>
      <geaui-toggle-v2 (toggled)="onToggleShowExcludedProducts($event)" data-cy="show-excluded-products"></geaui-toggle-v2>
    </div>
  </div>
  <geaui-search-bar-v2
    class="align-center geaui-col-desktop-xl-4 geaui-col-desktop-lg-3 geaui-col-desktop-md-3 geaui-col-tablet-lg-5 geaui-col-tablet-sm-5 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
    [placeholder]="'ASSET-LIST.SEARCH-PLACEHOLDER' | translate"
    [hasSuggestions]="false"
    [initialValue]="searchInput"
    [hasSuggestions]="false"
    [searchResults]="[]"
    (valueChanged)="searchInput = $event"
    (searchButtonClicked)="onSearchAssets()"
    (keydown)="onSearchTicketsByClickEnter($event)"
    (clearEvent)="clearSearch()"
    data-cy="search-bar">
  </geaui-search-bar-v2>
  <geaui-table-v2
    class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
    *ngIf="tableViewMode === 'Single'"
    [tableId]="assetTableColumnService.FLAT_TABLE_ID"
    [columnDefinitions]="assetColumns"
    [data]="products"
    [sortable]="true"
    [totalRecords]="totalRecordsFlat"
    [loading]="loading"
    [rowsClickable]="true"
    (rowClicked)="onRowClick($event)"
    data-cy="asset-table">
  </geaui-table-v2>
  <geaui-hierarchy-table-v2
    class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
    *ngIf="tableViewMode === 'Group'"
    [tableId]="assetTableColumnService.HIERARCHY_TABLE_ID"
    [columnDefinitions]="assetColumns"
    [data]="treeNodeProducts"
    [sortable]="true"
    [totalRecords]="totalRecordsHierarchy"
    [loading]="loading"
    [rowsClickable]="true"
    (rowClicked)="onRowClick($event)"
    data-cy="asset-table">
  </geaui-hierarchy-table-v2>
</div>
