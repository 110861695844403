<span class="section-header" *ngIf="sectionName !== undefined">{{ SECTION_TRANSLATION_PREFIX + sectionName | translate }}</span>
@for (pageIndex of getPagesArray(); track pageIndex) {
  <div class="attribute-wrapper">
    @for (attribute of getNextAttributeBlock(pageIndex); track attribute.name) {
      <div class="attribute" [ngClass]="{ 'bottom-margin': showBottomMargin($index, $last) }" data-cy="accordion-attributes">
        <span class="gea-font-heading-16 bottom-margin" data-cy="title">
          {{ ATTRIBUTE_TRANSLATION_PREFIX + attribute.name + '.NAME' | translate }}</span
        >

        @switch (attribute.type) {
          @case ('Text') {
            <span class="gea-font-subline-16" data-cy="value">{{ attribute.value }}</span>
          }
          @case ('Translation') {
            <span class="gea-font-subline-16" data-cy="value">{{
              ATTRIBUTE_TRANSLATION_PREFIX + attribute.name + '.VALUE.' + attribute.value | translate
            }}</span>
          }
          @case ('Date') {
            <span class="gea-font-subline-16" data-cy="value">{{
              attribute.value | date: 'shortDate' : undefined : translateService.currentLang
            }}</span>
          }
        }
      </div>
    }
  </div>
  <p-divider *ngIf="!$last"></p-divider>
}
