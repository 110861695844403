import { Component, DestroyRef, ElementRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProductAttribute } from '../../../../api/v1';
import { DividerModule } from 'primeng/divider';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'advance-attributes',
  standalone: true,
  imports: [CommonModule, TranslateModule, DividerModule],
  templateUrl: './attributes.component.html',
  styleUrl: './attributes.component.scss',
})
export class AttributesComponent implements OnInit {
  readonly PAGE_ROWS = 4;
  readonly ATTRIBUTE_TRANSLATION_PREFIX = 'ASSET-DETAIL.ATTRIBUTE.';
  readonly SECTION_TRANSLATION_PREFIX = 'ASSET-DETAIL.SECTION.';

  @Input() sectionName?: string;
  @Input() attributes!: ProductAttribute[];

  public pageSize = 16;

  constructor(
    private elRef: ElementRef,
    private destroyed: DestroyRef,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe(() => this.recalculatePageSize());

    this.recalculatePageSize();
  }

  public showBottomMargin(index: number, isLast: boolean): boolean {
    const isLastOfColumn = (index + 1) % this.PAGE_ROWS === 0;
    return !(isLast || isLastOfColumn);
  }

  public getPagesArray(): number[] {
    return Array.from({ length: this.getPageCount() }, (_, i) => i);
  }

  public getNextAttributeBlock(pageIndex: number): ProductAttribute[] {
    const attributeCount = this.attributes.length;
    const startIndex = pageIndex * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, attributeCount);
    return this.attributes.slice(startIndex, endIndex);
  }

  private getPageCount(): number {
    const attributeCount = this.attributes.length;
    return Math.ceil(attributeCount / this.pageSize);
  }

  private recalculatePageSize(): void {
    const element = this.elRef.nativeElement as HTMLDivElement;
    const attributeColumns = Number(element.computedStyleMap().get('--attribute-columns')?.toString());
    this.pageSize = attributeColumns * this.PAGE_ROWS;
  }
}
