import { Injectable } from '@angular/core';
import { DialogV2Service } from '@gea/digital-ui-lib';
import { JobInfoList, JobsApiService } from '../api/v1';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  jobStartedSubject = new Subject<string | undefined>();
  jobDataReloadedSubject = new Subject<void>();

  constructor(
    private jobApiService: JobsApiService,
    private dialog: DialogV2Service
  ) {}

  get jobStarted$(): Observable<string | undefined> {
    return this.jobStartedSubject.asObservable();
  }

  get jobDataReloaded$(): Observable<void> {
    return this.jobDataReloadedSubject.asObservable();
  }

  getJobs(): Observable<JobInfoList> {
    return this.jobApiService.getJobs(10);
  }

  setJobStarted(jobName?: string) {
    this.jobStartedSubject.next(jobName);
  }

  setJobDateReloaded() {
    this.jobDataReloadedSubject.next();
  }

  startJob(jobName: string): Observable<unknown> {
    switch (jobName) {
      case 'AssetBankSyncJob':
        return this.startAssetBankSyncJob();
      case 'RegisteredProductSyncJob':
        return this.startRegisteredProductSyncJob();
      case 'CrmSingleProductUpdateJob':
        return this.startCrmSingleProductUpdateJob();
      default:
        return of();
    }
  }

  startAssetBankSyncJob(): Observable<unknown> {
    this.dialog.open({
      closable: false,
      title: 'SCHEDULED-JOBS.ASSET-BANK-SYNC-START.RUN-LAST-MONTH-CONFIRM.TITLE',
      message: 'SCHEDULED-JOBS.ASSET-BANK-SYNC-START.RUN-LAST-MONTH-CONFIRM.MESSAGE',
      yes: 'SCHEDULED-JOBS.ASSET-BANK-SYNC-START.RUN-LAST-MONTH-CONFIRM.DIFFERENCE',
      no: 'SCHEDULED-JOBS.ASSET-BANK-SYNC-START.RUN-LAST-MONTH-CONFIRM.ALL',
      confirmCallback: () => {
        this.jobApiService.startAssetBankSyncJob().subscribe(() => {
          this.setJobStarted('AssetBankSyncJob');
        });
      },
      rejectCallback: () => {
        const currentDate = new Date();
        currentDate.setFullYear(1900);
        const isoDate = currentDate.toISOString();
        this.jobApiService.startAssetBankSyncJob(isoDate).subscribe(() => {
          this.setJobStarted('AssetBankSyncJob');
        });
      },
    });

    return of();
  }

  startRegisteredProductSyncJob(): Observable<unknown> {
    return this.jobApiService.startRegisteredProductSyncJob();
  }

  startCrmSingleProductUpdateJob(): Observable<unknown> {
    return this.jobApiService.startCrmSingleUpdateJob();
  }
}
