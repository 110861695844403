/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VisibilityType } from './visibilityType';
import { ProductAttributeSection } from './productAttributeSection';
import { ProductAttribute } from './productAttribute';


export interface ProductAttributeGroup { 
    id: string;
    name: string;
    sortOrder?: number;
    visibilityType?: VisibilityType;
    attributes: Array<ProductAttribute>;
    sections?: Array<ProductAttributeSection>;
}
export namespace ProductAttributeGroup {
}


